import { HubConnectionBuilder } from '@microsoft/signalr';
import { useCypressSignalRMock } from 'cypress-signalr-mock';

const buildUrl = () => {
  let hostname = document.location.hostname;

  if (hostname !== "app.datarios.cz" && hostname !== "localhost") {
    hostname = "notifications." + hostname;
  } else if (hostname === "app.datarios.cz") {
    hostname = "notifications.datarios.cz";
  }

  let rootUrl = document.location.protocol + "//" + hostname;
  if (hostname === "localhost") {
    rootUrl += ":8192";
  }

  return rootUrl + "/eventhub";
};

export const connection = useCypressSignalRMock('testHub') ?? new HubConnectionBuilder()
  .withUrl(buildUrl())
  .withAutomaticReconnect()
  .configureLogging(logging => {
    logging.AddConsole();
  })
  .build();

export const isConnected = () => {
  return connection.connectionStarted && connection.state === "Connected";
};

export const isDisconnected = () => {
  return !connection.connectionStarted && connection.state === "Disconnected";
};
