import _ from 'lodash';

import * as types from '../actions/actionTypes';
import { WORKFLOW } from '../common/types/signalRTypes';
import { fetchDocumentsForApproval } from '../actions/userActions';

const updateWf = (data, store, message) => {
  const groupIndex = data.findIndex(group => group.workflowQueueRecords.some(recordRow => recordRow.id === message.id));

  data.forEach((group, i) => {
    let records = _.cloneDeep(group.workflowQueueRecords);
    const hasSrFlag = records.some(record => record.srFlag === true && record.srFlagUserId === message.userId);

    if (hasSrFlag) {
      records = records.map(record => {
        if (record.srFlagUserId === message.userId) {
          record.srFlagUser = undefined;
          record.srFlag = false;
        }
        return record;
      });
    }

    if (i === groupIndex) {
      records = records.map(record => {
        if (record.id === message.id) {
          record.srFlagUserId = message.userId;
          record.srFlagUser = message.email;
          record.srFlag = true;
        }
        return record;
      });
    }

    if (hasSrFlag || i === groupIndex) {
      store.dispatch({
        type: types.TABLE_MUTATE_ROW,
        data: {
          id: group.id,
          workflowQueueRecords: records
        }
      });
    }
  });
};

export const registerEvents = (store, connection) => {
  connection.on(WORKFLOW.Selected, (message) => {
    let { table: { data } } = store.getState();
    if (data) {
      updateWf(data, store, message);
    }
  });
};

export const doActions = (action, connection) => {
  if (action.type === types.SR_WORKFLOW_SELECT_BY_USER) {
    if (connection.connectionStarted) {
      connection.invoke(WORKFLOW.Select, action.payload.id);
    }
  }
};
