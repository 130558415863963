/* eslint-disable */
import * as types from '../actions/actionTypes';

const navigationMiddleware = history => store => next => action => {
  if (action.type !== types.NAVIGATE_TO) {
    return next(action);
  }

  if (action.state !== undefined) {
    history.push(action.path, action.state);
  } else {
    history.push(action.path);
  }


  return next(action);
};

export default navigationMiddleware;
