export const NOTIFICATION_TYPES = {
  users: 'users',
  userInvitations: 'userInvitations',
  companyInvitations: 'companyInvitations',
  invoices_incoming: 'invoices.incoming',
  invoices_outgoing: 'invoices.outgoing',
  partnerInvitations: 'partnerInvitations',
  partnership: 'partnership',
  workflow_inputQueue: 'workflow.inputQueue',
  workflow_outputQueue: 'workflow.outputQueue',
  workflow_agreementProcess: 'workflow.agreementProcess'
};

export const NOTIFICATION_LOG_TYPES = {
  NotSet: 0,
  Agenda: 1,
  User: 2,
  Company: 3,
  Credit: 4,
  Receipt: 5,
  UserPermission: 6,
  UserInvitation: 7,
  CompanyInvitation: 8,
  Partner: 9,
  Invoice: 15,
  WorkflowQueueGroup: 20,
  WorkflowQueueRecord: 21,
  PartnerInvitation: 30,
  Setting: 31,
  BillingAgendaBalance: 40
};

export const NOTIFICATION_LOG_SUB_TYPES = {
  NotSet: 0,
  Partnership: 10,
  InvoiceIncoming: 16,
  InvoiceOutgoing: 17,
  WorkflowInputQueue: 22,
  WorkflowOutputQueue: 23,
  WorkflowAgreementProcess: 24
};

export type Log = {
  id: number,
  type: number,
  subType: number,
  userId: number,
  additionalInfo: string | null,
  dateOfCreate: string
};

export type ReadLog = {
  id: number,
  dateOfCreate: Date,
  dateOfRead: Date
};
