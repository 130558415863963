export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const TOGGLE_ACCOUNT_MENU = 'TOGGLE_ACCOUNT_MENU';
export const CLOSE_ACCOUNT_MENU = 'CLOSE_ACCOUNT_MENU';
export const TOGGLE_ACCOUNT_MENU_SELECT_ITEM = 'TOGGLE_ACCOUNT_MENU_SELECT_ITEM';

// Sidebar
export const SIDEBAR_TOGGLE = 'sidebar/TOGGLE';
export const SIDEBAR_HIDE = 'sidebar/HIDE';
export const SIDEBAR_SHOW = 'sidebar/SHOW';
export const SIDEBAR_VIEW_MODE = 'sidebar/MODE';

export const NOTIFICATIONS_FETCH_REQUEST = 'notification/FETCH_REQUEST';
export const NOTIFICATIONS_FETCH_SUCCESS = 'notification/FETCH_SUCCESS';
export const NOTIFICATIONS_FETCH_FAILURE = 'notification/FETCH_FAILURE';

export const NOTIFICATION_SAW = 'notification/SAW';
export const NOTIFICATIONS_SAW = 'notification/SAW_ALL';
export const NOTIFICATIONS_READ = 'notification/READ';
export const NOTIFICATIONS_CLEAR = 'notification/CLEAR';

// Navigation
export const NAVIGATE_TO = 'navigation/NAVIGATE_TO';

// User
export const USER_CLEAR = 'user/CLEAR';
export const USER_SIGN_OUT = 'user/SIGN_OUT';

export const USER_RENEW_TOKEN_REQUEST = 'user/RENEW_TOKEN_REQUEST';
export const USER_RENEW_TOKEN_SUCCESS = 'user/RENEW_TOKEN_SUCCESS';
export const USER_RENEW_TOKEN_FAILURE = 'user/RENEW_TOKEN_FAILURE';

export const USER_UPDATE_TOKEN_EXPIRATION = 'user/UPDATE_TOKEN_EXPIRATION';

export const USER_SIGN_IN_REQUEST = 'user/SIGN_IN_REQUEST';
export const USER_SIGN_IN_SUCCESS = 'user/SIGN_IN_SUCCESS';
export const USER_SIGN_IN_FAILURE = 'user/SIGN_IN_FAILURE';

export const USER_VERIFY_EMAIL_REQUEST = 'user/USER_VERIFY_EMAIL_REQUEST';
export const USER_VERIFY_EMAIL_SUCCESS = 'user/USER_VERIFY_EMAIL_SUCCESS';
export const USER_VERIFY_EMAIL_FAILURE = 'user/USER_VERIFY_EMAIL_FAILURE';

export const USER_GET_COMPANY_INFO_REQUEST = 'user/GET_COMPANY_INFO_REQUEST';
export const USER_GET_COMPANY_INFO_SUCCESS = 'user/GET_COMPANY_INFO_SUCCESS';
export const USER_GET_COMPANY_INFO_FAILURE = 'user/GET_COMPANY_INFO_FAILURE';

export const USER_FETCH_AGENDAS_REQUEST = 'user/FETCH_AGENDAS_REQUEST';
export const USER_FETCH_AGENDAS_SUCCESS = 'user/FETCH_AGENDAS_SUCCESS';
export const USER_FETCH_AGENDAS_FAILURE = 'user/FETCH_AGENDAS_FAILURE';

export const USER_COMPANY_INVITATIONS_REQUEST = 'user/COMPANY_INVITATIONS_REQUEST';
export const USER_COMPANY_INVITATIONS_SUCCESS = 'user/COMPANY_INVITATIONS_SUCCESS';
export const USER_COMPANY_INVITATIONS_FAILURE = 'user/COMPANY_INVITATIONS_FAILURE';

export const USER_PASSWORD_SET_REQUEST = 'user/PASSWORD_SET_REQUEST';
export const USER_PASSWORD_SET_SUCCESS = 'user/PASSWORD_SET_SUCCESS';
export const USER_PASSWORD_SET_FAILURE = 'user/PASSWORD_SET_FAILURE';

export const USER_PASSWORD_RESET_REQUEST = 'user/PASSWORD_RESET_REQUEST';
export const USER_PASSWORD_RESET_SUCCESS = 'user/PASSWORD_RESET_SUCCESS';
export const USER_PASSWORD_RESET_FAILURE = 'user/PASSWORD_RESET_FAILURE';

export const USER_INVITATION_SET_STATUS_REQUEST = 'user/SET_STATUS_INVITATION_REQUEST';
export const USER_INVITATION_SET_STATUS_SUCCESS = 'user/SET_STATUS_INVITATION_SUCCESS';
export const USER_INVITATION_SET_STATUS_FAILURE = 'user/SET_STATUS_INVITATION_FAILURE';

export const USER_INVITATION_REMOVE_ROW = 'user/INVITATION_REMOVE_ROW';

export const USER_SIGN_UP_USER_REQUEST = 'user/SIGN_UP_USER_REQUEST';
export const USER_SIGN_UP_USER_SUCCESS = 'user/SIGN_UP_USER_SUCCESS';
export const USER_SIGN_UP_USER_FAILURE = 'user/SIGN_UP_USER_FAILURE';

export const USER_SIGN_UP_COMPANY_REQUEST = 'user/SIGN_UP_COMPANY_REQUEST';
export const USER_SIGN_UP_COMPANY_SUCCESS = 'user/SIGN_UP_COMPANY_SUCCESS';
export const USER_SIGN_UP_COMPANY_FAILURE = 'user/SIGN_UP_COMPANY_FAILURE';

export const USER_SIGN_UP_COMPLETE_USER_REQUEST = 'user/SIGN_UP_COMPLETE_USER_REQUEST';
export const USER_SIGN_UP_COMPLETE_USER_SUCCESS = 'user/SIGN_UP_COMPLETE_USER_SUCCESS';
export const USER_SIGN_UP_COMPLETE_USER_FAILURE = 'user/SIGN_UP_COMPLETE_USER_FAILURE';

export const USER_SIGN_UP_VIA_DATA_BOX_REQUEST = 'user/SIGN_UP_VIA_DATA_BOX_REQUEST';
export const USER_SIGN_UP_VIA_DATA_BOX_SUCCESS = 'user/SIGN_UP_VIA_DATA_BOX_SUCCESS';
export const USER_SIGN_UP_VIA_DATA_BOX_FAILURE = 'user/SIGN_UP_VIA_DATA_BOX_FAILURE';

export const USER_INVITATION_EXPIRE_REQUEST = 'user/EXPIRE_INVITATION_REQUEST';
export const USER_INVITATION_EXPIRE_SUCCESS = 'user/EXPIRE_INVITATION_SUCCESS';
export const USER_INVITATION_EXPIRE_FAILURE = 'user/EXPIRE_INVITATION_FAILURE';

// Payments
export const PAYMENT_CREATE_REGISTRATION_REQUEST = 'payment/CREATE_REGISTRATION_REQUEST';
export const PAYMENT_CREATE_REGISTRATION_SUCCESS = 'payment/CREATE_REGISTRATION_SUCCESS';
export const PAYMENT_CREATE_REGISTRATION_FAILURE = 'payment/CREATE_REGISTRATION_FAILURE';

export const PAYMENT_REGISTRATION_INFO_REQUEST = 'payment/REGISTRATION_INFO_REQUEST';
export const PAYMENT_REGISTRATION_INFO_SUCCESS = 'payment/REGISTRATION_INFO_SUCCESS';
export const PAYMENT_REGISTRATION_INFO_FAILURE = 'payment/REGISTRATION_INFO_FAILURE';

export const PAYMENT_AUTHORIZED_INFO_REQUEST = 'payment/AUTHORIZED_INFO_REQUEST';
export const PAYMENT_AUTHORIZED_INFO_SUCCESS = 'payment/AUTHORIZED_INFO_SUCCESS';
export const PAYMENT_AUTHORIZED_INFO_FAILURE = 'payment/AUTHORIZED_INFO_FAILURE';

// Application
export const APP_RESET = 'app/RESET';
export const APP_CHANGE_LANG = 'app/CHANGE_LANG';
export const APP_OPEN_WARNING_DIALOG = 'app/OPEN_WARNING_DIALOG';
export const APP_CLOSE_WARNING_DIALOG = 'app/CLOSE_WARNING_DIALOG';
export const APP_RESOLUTION = 'app/RESOLUTION';
export const APP_CURRENT_PAGE = 'app/CURRENT_PAGE';
export const APP_VIEW_MODE = 'app/VIEW_MODE';
export const APP_GRID_MODE = 'app/GRID_MODE';
export const APP_HAS_UNSAVED_CHANGES = 'app/HAS_UNSAVED_CHANGES';
export const APP_USER_WORKFLOW_FILTER = 'app/USER_WORKFLOW_FILTER';

export const APP_CLEAR_REQUEST = 'app/CLEAR_REQUEST';
export const APP_CLEAR_SUCCESS = 'app/CLEAR_SUCCESS';
export const APP_CLEAR_FAILURE = 'app/CLEAR_FAILURE';

export const APP_AGENDA_SET_REQUEST = 'app/AGENDA_SET_REQUEST';
export const APP_AGENDA_SET_SUCCESS = 'app/AGENDA_SET_SUCCESS';
export const APP_AGENDA_SET_FAILURE = 'app/AGENDA_SET_FAILURE';

export const APP_NEED_SIGNOUT_DIALOG_OPEN = 'app/NEED_SIGNOUT_DIALOG_OPEN';
export const APP_NEED_SIGNOUT_DIALOG_CLOSE = 'app/NEED_SIGNOUT_DIALOG_CLOSE';

// Company invitations
export const COMPANY_INVITATION_SET_REQUEST = 'company_invitation/SET_REQUEST';
export const COMPANY_INVITATION_SET_SUCCESS = 'company_invitation/SET_SUCCESS';
export const COMPANY_INVITATION_SET_FAILURE = 'company_invitation/SET_FAILURE';
export const COMPANY_INVITATION_REMOVE_ROW = 'company_invitation/REMOVE_ROW';

export const COMPANY_INVITATION_CREATE = 'company_invitation/CREATE';
export const COMPANY_INVITATION_CREATE_REQUEST = 'company_invitation/CREATE_REQUEST';
export const COMPANY_INVITATION_CREATE_SUCCESS = 'company_invitation/CREATE_SUCCESS';
export const COMPANY_INVITATION_CREATE_FAILURE = 'company_invitation/CREATE_FAILURE';

export const COMPANY_INVITATION_ACCEPT_REQUEST = 'company_invitation/ACCEPT_REQUEST';
export const COMPANY_INVITATION_ACCEPT_SUCCESS = 'company_invitation/ACCEPT_SUCCESS';
export const COMPANY_INVITATION_ACCEPT_FAILURE = 'company_invitation/ACCEPT_FAILURE';

export const COMPANY_INVITATION_REJECT_REQUEST = 'company_invitation/REJECT_REQUEST';
export const COMPANY_INVITATION_REJECT_SUCCESS = 'company_invitation/REJECT_SUCCESS';
export const COMPANY_INVITATION_REJECT_FAILURE = 'company_invitation/REJECT_FAILURE';

// Alerts
export const ALERTS_ADD = 'alerts/ADD';
export const ALERTS_REMOVE = 'alerts/REMOVE';
export const ALERTS_CLEAR = 'alerts/CLEAR';

// Table
export const TABLE_RESET = 'table/RESET';
export const TABLE_CLEAR = 'table/CLEAR';
export const TABLE_REFESH = 'table/REFRESH';
export const TABLE_FETCH_DATA_REQUEST = 'table/FETCH_DATA_REQUEST';
export const TABLE_FETCH_DATA_SUCCESS = 'table/FETCH_DATA_SUCCESS';
export const TABLE_FETCH_DATA_FAILURE = 'table/FETCH_DATA_FAILURE';

export const TABLE_ADD_ROW_REQUEST = 'table/ADD_ROW_REQUEST';
export const TABLE_ADD_ROW_SUCCESS = 'table/ADD_ROW_SUCCESS';
export const TABLE_ADD_ROW_FAILURE = 'table/ADD_ROW_FAILURE';


export const TABLE_UPDATE_ROW = 'table/UPDATE_ROW';
export const TABLE_UPDATE_ROW_REQUEST = 'table/UPDATE_ROW_REQUEST';
export const TABLE_UPDATE_ROW_SUCCESS = 'table/UPDATE_ROW_SUCCESS';
export const TABLE_UPDATE_ROW_FAILURE = 'table/UPDATE_ROW_FAILURE';

export const TABLE_UPDATE_ROW_STATE_REQUEST = 'table/UPDATE_ROW_STATE_REQUEST';
export const TABLE_UPDATE_ROW_STATE_SUCCESS = 'table/UPDATE_ROW_STATE_SUCCESS';
export const TABLE_UPDATE_ROW_STATE_FAILURE = 'table/UPDATE_ROW_STATE_FAILURE';

export const TABLE_UPDATE_ROW_PARTIALLY = 'table/UPDATE_ROW_PARTIALLY';
export const TABLE_UPDATE_ROW_PARTIALLY_REQUEST = 'table/UPDATE_ROW_PARTIALLY_REQUEST';
export const TABLE_UPDATE_ROW_PARTIALLY_SUCCESS = 'table/UPDATE_ROW_PARTIALLY_SUCCESS';
export const TABLE_UPDATE_ROW_PARTIALLY_FAILURE = 'table/UPDATE_ROW_PARTIALLY_FAILURE';

export const TABLE_RELOAD_ROW_PROPERTY = 'table/RELOAD_ROW_PROPERTY';
export const TABLE_RELOAD_ROW_PROPERTY_REQUEST = 'table/RELOAD_ROW_PROPERTY_REQUEST';
export const TABLE_RELOAD_ROW_PROPERTY_SUCCESS = 'table/RELOAD_ROW_PROPERTY_SUCCESS';
export const TABLE_RELOAD_ROW_PROPERTY_FAILURE = 'table/RELOAD_ROW_PROPERTY_FAILURE';

export const TABLE_PAGE_CHANGE = 'table/PAGE_CHANGE';
export const TABLE_SORT_CHANGE = 'table/SORT_CHANGE';
export const TABLE_FILTER_CHANGE = 'table/FILTER_CHANGE';
export const TABLE_FILTER_CLEAR = 'table/FILTER_CLEAR';
export const TABLE_CACHE_DATA = 'table/CACHE_DATA';
export const TABLE_SELECT_ROW = 'table/SELECT_ROW';
export const TABLE_DESELECT_ALL_ROWS = 'table/DESELECT_ALL_ROWS';
export const TABLE_SELECT_ROWS = 'table/SELECT_ROWS';
export const TABLE_SELECT_ALL_ROWS = 'table/SELECT_ALL_ROWS';
export const TABLE_EXPAND_CHANGE = 'table/EXPAND_CHANGE';
export const TABLE_EXPAND_ALL = 'table/EXPAND_ALL';
export const TABLE_COLLAPSE_ALL = 'table/COLLAPSE_ALL';
export const TABLE_MUTATE = 'table/MUTATE';
export const TABLE_MUTATE_ROW = 'table/MUTATE_ROW';
export const TABLE_UPDATE_COLUMNS = 'table/UPDATE_COLUMNS';
export const TABLE_UPDATE_URL = 'table/UPDATE_URL';

export const TABLE_REMOVE_ROWS = 'table/REMOVE_ROWS';
export const TABLE_REMOVE_ROWS_REQUEST = 'table/REMOVE_ROWS_REQUEST';
export const TABLE_REMOVE_ROWS_SUCCESS = 'table/REMOVE_ROWS_SUCCESS';
export const TABLE_REMOVE_ROWS_FAILURE = 'table/REMOVE_ROWS_FAILURE';

export const TABLE_SET_TOTAL = 'table/SET_TOTAL';

// Partner invitations info
export const PARTNER_INVITATION_GET_INFO_REQUEST = 'partnerInvitation/ADD_GET_INFO_REQUEST';
export const PARTNER_INVITATION_GET_INFO_SUCCESS = 'partnerInvitation/ADD_GET_INFO_SUCCESS';
export const PARTNER_INVITATION_GET_INFO_FAILURE = 'partnerInvitation/ADD_GET_INFO_FAILURE';
export const PARTNER_INVITATION_GET = 'partnerInvitation/ADD_GET_INFO';

// Partner invitations
export const PARTNER_INVITATION_ACCEPT_BY_TOKEN_REQUEST = 'partnerInvitation/ACCEPT_BY_TOKEN_REQUEST';
export const PARTNER_INVITATION_ACCEPT_BY_TOKEN_SUCCESS = 'partnerInvitation/ACCEPT_BY_TOKEN_SUCCESS';
export const PARTNER_INVITATION_ACCEPT_BY_TOKEN_FAILURE = 'partnerInvitation/ACCEPT_BY_TOKEN_FAILURE';

export const PARTNER_INVITATION_REJECT_BY_TOKEN_REQUEST = 'partnerInvitation/REJECT_BY_TOKEN_REQUEST';
export const PARTNER_INVITATION_REJECT_BY_TOKEN_SUCCESS = 'partnerInvitation/REJECT_BY_TOKEN_SUCCESS';
export const PARTNER_INVITATION_REJECT_BY_TOKEN_FAILURE = 'partnerInvitation/REJECT_BY_TOKEN_FAILURE';

export const PARTNER_INVITATION_ADD_REQUEST = 'partnerInvitation/ADD_REQUEST';
export const PARTNER_INVITATION_ADD_SUCCESS = 'partnerInvitation/ADD_SUCCESS';
export const PARTNER_INVITATION_ADD_FAILURE = 'partnerInvitation/ADD_FAILURE';
export const PARTNER_INVITATION_ADD = 'partnerInvitation/ADD';

export const COMPANY_VERIFICATION_FETCH_DATA_REQUEST = 'companyVerification/FETCH_DATA_REQUEST';
export const COMPANY_VERIFICATION_FETCH_DATA_SUCCESS = 'companyVerification/FETCH_DATA_SUCCESS';
export const COMPANY_VERIFICATION_FETCH_DATA_FAILURE = 'companyVerification/FETCH_DATA_FAILURE';

export const COMPANY_VERIFICATION_RESTART_PAYMENT_DATA_REQUEST = 'companyVerification/RESTART_PAYMENT_REQUEST';
export const COMPANY_VERIFICATION_RESTART_PAYMENT_DATA_SUCCESS = 'companyVerification/RESTART_PAYMENT_SUCCESS';
export const COMPANY_VERIFICATION_RESTART_PAYMENT_DATA_FAILURE = 'companyVerification/RESTART_PAYMENT_FAILURE';

export const COMPANY_VERIFICATION_SENT_VERIFICATION_MESSAGE_REQUEST = 'companyVerification/SENT_VERIFICATION_MESSAGE_REQUEST';
export const COMPANY_VERIFICATION_SENT_VERIFICATION_MESSAGE_SUCCESS = 'companyVerification/SENT_VERIFICATION_MESSAGE_SUCCESS';
export const COMPANY_VERIFICATION_SENT_VERIFICATION_MESSAGE_FAILURE = 'companyVerification/SENT_VERIFICATION_MESSAGE_FAILURE';

export const COMPANY_VERIFICATION_VERIFY_REQUEST = 'companyVerification/FETCH_VERIFY_REQUEST';
export const COMPANY_VERIFICATION_VERIFY_SUCCESS = 'companyVerification/FETCH_VERIFY_SUCCESS';
export const COMPANY_VERIFICATION_VERIFY_FAILURE = 'companyVerification/FETCH_VERIFY_FAILURE';
export const COMPANY_VERIFICATION_VERIFY = 'companyVerification/FETCH_VERIFY';

export const MY_ACCOUNT_FETCH_REQUEST = 'myAccount/FETCH_REQUEST';
export const MY_ACCOUNT_FETCH_SUCCESS = 'myAccount/FETCH_SUCCESS';
export const MY_ACCOUNT_FETCH_FAILURE = 'myAccount/FETCH_FAILURE';

export const MY_ACCOUNT_UPDATE = 'myAccount/UPDATE';
export const MY_ACCOUNT_UPDATE_REQUEST = 'myAccount/UPDATE_REQUEST';
export const MY_ACCOUNT_UPDATE_SUCCESS = 'myAccount/UPDATE_SUCCESS';
export const MY_ACCOUNT_UPDATE_FAILURE = 'myAccount/UPDATE_FAILURE';

export const MY_ACCOUNT_SET_NEW_PASSWORD = 'myAccount/SET_NEW_PASSWORD';
export const MY_ACCOUNT_SET_NEW_PASSWORD_REQUEST = 'myAccount/SET_NEW_PASSWORD_REQUEST';
export const MY_ACCOUNT_SET_NEW_PASSWORD_SUCCESS = 'myAccount/SET_NEW_PASSWORD_SUCCESS';
export const MY_ACCOUNT_SET_NEW_PASSWORD_FAILURE = 'myAccount/SET_NEW_PASSWORD_FAILURE';


export const APP_CREATE = 'app/CREATE';
export const APP_CREATE_REQUEST = 'app/CREATE_REQUEST';
export const APP_CREATE_SUCCESS = 'app/CREATE_SUCCESS';
export const APP_CREATE_FAILURE = 'app/CREATE_FAILURE';

export const APP_FETCH = 'app/FETCH';
export const APP_FETCH_REQUEST = 'app/FETCH_REQUEST';
export const APP_FETCH_SUCCESS = 'app/FETCH_SUCCESS';
export const APP_FETCH_FAILURE = 'app/FETCH_FAILURE';

export const APP_UPDATE = 'app/UPDATE';
export const APP_UPDATE_REQUEST = 'app/UPDATE_REQUEST';
export const APP_UPDATE_SUCCESS = 'app/UPDATE_SUCCESS';
export const APP_UPDATE_FAILURE = 'app/UPDATE_FAILURE';

export const APP_DELETE_REQUEST = 'app/DELETE_REQUEST';
export const APP_DELETE_SUCCESS = 'app/DELETE_SUCCESS';
export const APP_DELETE_FAILURE = 'app/DELETE_FAILURE';

export const DASHBOARD_CHART_TOTAL_INVOICES_DATA = 'dashboard/CHART_TOTAL_INVOICES_DATA';
export const DASHBOARD_CHART_TOTAL_INVOICES_DATA_REQUEST = 'dashboard/CHART_TOTAL_INVOICES_DATA_REQUEST';
export const DASHBOARD_CHART_TOTAL_INVOICES_DATA_SUCCESS = 'dashboard/CHART_TOTAL_INVOICES_DATA_SUCCESS';
export const DASHBOARD_CHART_TOTAL_INVOICES_DATA_FAILURE = 'dashboard/CHART_TOTAL_INVOICES_DATA_FAILURE';

export const DASHBOARD_CHART_DONUT_TOTAL_INVOICES_DATA_REQUEST = 'dashboard/CHART_DONUT_TOTAL_INVOICES_DATA_REQUEST';
export const DASHBOARD_CHART_DONUT_TOTAL_INVOICES_DATA_SUCCESS = 'dashboard/CHART_DONUT_TOTAL_INVOICES_DATA_SUCCESS';
export const DASHBOARD_CHART_DONUT_TOTAL_INVOICES_DATA_FAILURE = 'dashboard/CHART_DONUT_TOTAL_INVOICES_DATA_FAILURE';

export const DASHBOARD_HOW_MUCH_YOU_SAVED_REQUEST = 'dashboard/HOW_MUCH_YOU_SAVED_REQUEST';
export const DASHBOARD_HOW_MUCH_YOU_SAVED_SUCCESS = 'dashboard/HOW_MUCH_YOU_SAVED_SUCCESS';
export const DASHBOARD_HOW_MUCH_YOU_SAVED_FAILURE = 'dashboard/HOW_MUCH_YOU_SAVED_FAILURE';


export const COMPANY_FETCH_DATA = 'company/FETCH_DATA';
export const COMPANY_FETCH_DATA_REQUEST = 'company/FETCH_DATA_REQUEST';
export const COMPANY_FETCH_DATA_SUCCESS = 'company/FETCH_DATA_SUCCESS';
export const COMPANY_FETCH_DATA_FAILURE = 'company/FETCH_DATA_FAILURE';

export const COMPANY_UPDATE_DATA = 'company/UPDATE_DATA';
export const COMPANY_UPDATE_DATA_REQUEST = 'company/UPDATE_DATA_REQUEST';
export const COMPANY_UPDATE_DATA_SUCCESS = 'company/UPDATE_DATA_SUCCESS';
export const COMPANY_UPDATE_DATA_FAILURE = 'company/UPDATE_DATA_FAILURE';

export const COMPANY_UPDATED_DATA = 'company/UPDATED_DATA';

export const USER_SIGN_UP_CREATE_COMPANY_TOKEN_REQUEST = 'user/SIGN_UP_CREATE_COMPANY_TOKEN_REQUEST';
export const USER_SIGN_UP_CREATE_COMPANY_TOKEN_SUCCESS = 'user/SIGN_UP_CREATE_COMPANY_TOKEN_SUCCESS';
export const USER_SIGN_UP_CREATE_COMPANY_TOKEN_FAILURE = 'user/SIGN_UP_CREATE_COMPANY_TOKEN_FAILURE';

export const PERMISSIONS_FETCH_REQUEST = 'permissions/FETCH_REQUEST';
export const PERMISSIONS_FETCH_SUCCESS = 'permissions/FETCH_SUCCESS';
export const PERMISSIONS_FETCH_FAILURE = 'permissions/FETCH_FAILURE';

export const AGENDA_USERS_FETCH_REQUEST = 'agenda/USERS_FETCH_REQUEST';
export const AGENDA_USERS_FETCH_SUCCESS = 'agenda/USERS_FETCH_SUCCESS';
export const AGENDA_USERS_FETCH_FAILURE = 'agenda/USERS_FETCH_FAILURE';

export const AGENDA_PARTNERS_FETCH_REQUEST = 'agenda/PARTNERS_FETCH_REQUEST';
export const AGENDA_PARTNERS_FETCH_SUCCESS = 'agenda/PARTNERS_FETCH_SUCCESS';
export const AGENDA_PARTNERS_FETCH_FAILURE = 'agenda/PARTNERS_FETCH_FAILURE';

export const USER_PERMISSIONS_BULK_UPDATE = 'userPermissions/BULK_UPDATE';
export const USER_PERMISSIONS_BULK_UPDATE_REQUEST = 'userPermissions/BULK_UPDATE_REQUEST';
export const USER_PERMISSIONS_BULK_UPDATE_SUCCESS = 'userPermissions/BULK_UPDATE_SUCCESS';
export const USER_PERMISSIONS_BULK_UPDATE_FAILURE = 'userPermissions/BULK_UPDATE_FAILURE';

export const PERMISSIONS_ROLES_FETCH_DATA_REQUEST = 'permissions/ROLES_FETCH_DATA_REQUEST';
export const PERMISSIONS_ROLES_FETCH_DATA_SUCCESS = 'permissions/ROLES_FETCH_DATA_SUCCESS';
export const PERMISSIONS_ROLES_FETCH_DATA_FAILURE = 'permissions/ROLES_FETCH_DATA_FAILURE';

export const WORKFLOW_VARIABLE_TYPES_FETCH = 'worfklow/VARIABLE_TYPES_FETCH_DATA';
export const WORKFLOW_VARIABLE_TYPES_FETCH_REQUEST = 'worfklow/VARIABLE_TYPES_FETCH_DATA_REQUEST';
export const WORKFLOW_VARIABLE_TYPES_FETCH_SUCCESS = 'worfklow/VARIABLE_TYPES_FETCH_DATA_SUCCESS';
export const WORKFLOW_VARIABLE_TYPES_FETCH_FAILURE = 'worfklow/VARIABLE_TYPES_FETCH_DATA_FAILURE';

export const WORKFLOW_VARIABLES_SET = 'worfklow/VARIABLES_SET';

export const WORKFLOW_VARIABLE_ADD = 'worfklow/VARIABLE_ADD';
export const WORKFLOW_VARIABLE_ADD_REQUEST = 'worfklow/VARIABLE_ADD_REQUEST';
export const WORKFLOW_VARIABLE_ADD_SUCCESS = 'worfklow/VARIABLE_ADD_SUCCESS';
export const WORKFLOW_VARIABLE_ADD_FAILURE = 'worfklow/VARIABLE_ADD_FAILURE';

export const WORKFLOW_VARIABLE_UPDATE = 'worfklow/VARIABLE_UPDATE';
export const WORKFLOW_VARIABLE_UPDATE_REQUEST = 'worfklow/VARIABLE_UPDATE_REQUEST';
export const WORKFLOW_VARIABLE_UPDATE_SUCCESS = 'worfklow/VARIABLE_UPDATE_SUCCESS';
export const WORKFLOW_VARIABLE_UPDATE_FAILURE = 'worfklow/VARIABLE_UPDATE_FAILURE';

export const WORKFLOW_VARIABLE_REMOVE_REQUEST = 'worfklow/VARIABLE_REMOVE_REQUEST';
export const WORKFLOW_VARIABLE_REMOVE_SUCCESS = 'worfklow/VARIABLE_REMOVE_SUCCESS';
export const WORKFLOW_VARIABLE_REMOVE_FAILURE = 'worfklow/VARIABLE_REMOVE_FAILURE';

export const WORKFLOW_AGREEMENT_PROCESS_STEPS_FETCH_BY_SCENARIO_REQUEST = 'worfklow/AGREEMENT_PROCESS_STEPS_FETCH_BY_SCENARIO_REQUEST';
export const WORKFLOW_AGREEMENT_PROCESS_STEPS_FETCH_BY_SCENARIO_SUCCESS = 'worfklow/AGREEMENT_PROCESS_STEPS_FETCH_BY_SCENARIO_SUCCESS';
export const WORKFLOW_AGREEMENT_PROCESS_STEPS_FETCH_BY_SCENARIO_FAILURE = 'worfklow/AGREEMENT_PROCESS_STEPS_FETCH_BY_SCENARIO_FAILURE';

export const WORKFLOW_AGREEMENT_PROCESS_STEP_ADD_REQUEST = 'worfklow/AGREEMENT_PROCESS_STEP_ADD_REQUEST';
export const WORKFLOW_AGREEMENT_PROCESS_STEP_ADD_SUCCESS = 'worfklow/AGREEMENT_PROCESS_STEP_ADD_SUCCESS';
export const WORKFLOW_AGREEMENT_PROCESS_STEP_ADD_FAILURE = 'worfklow/AGREEMENT_PROCESS_STEP_ADD_FAILURE';
export const WORKFLOW_AGREEMENT_PROCESS_STEP_ADD = 'worfklow/AGREEMENT_PROCESS_STEP_ADD';

export const WORKFLOW_AGREEMENT_PROCESS_STEP_REMOVE_REQUEST = 'worfklow/AGREEMENT_PROCESS_STEP_REMOVE_REQUEST';
export const WORKFLOW_AGREEMENT_PROCESS_STEP_REMOVE_SUCCESS = 'worfklow/AGREEMENT_PROCESS_STEP_REMOVE_SUCCESS';
export const WORKFLOW_AGREEMENT_PROCESS_STEP_REMOVE_FAILURE = 'worfklow/AGREEMENT_PROCESS_STEP_REMOVE_FAILURE';

export const WORKFLOW_AGREEMENT_PROCESS_STEP_USERS_BULK_UPDATE = 'worfklow/AGREEMENT_PROCESS_STEP_USER_BULK_UPDATE';
export const WORKFLOW_AGREEMENT_PROCESS_STEP_USERS_BULK_UPDATE_REQUEST = 'worfklow/AGREEMENT_PROCESS_STEP_USER_BULK_UPDATE_REQUEST';
export const WORKFLOW_AGREEMENT_PROCESS_STEP_USERS_BULK_UPDATE_SUCCESS = 'worfklow/AGREEMENT_PROCESS_STEP_USER_BULK_UPDATE_SUCCESS';
export const WORKFLOW_AGREEMENT_PROCESS_STEP_USERS_BULK_UPDATE_FAILURE = 'worfklow/AGREEMENT_PROCESS_STEP_USER_BULK_UPDATE_FAILURE';

export const WORKFLOW_AGREEMENT_PROCESS_STEP_UPDATE = 'worfklow/AGREEMENT_PROCESS_STEP_UPDATE';
export const WORKFLOW_AGREEMENT_PROCESS_STEP_UPDATE_REQUEST = 'worfklow/AGREEMENT_PROCESS_STEP_UPDATE_REQUEST';
export const WORKFLOW_AGREEMENT_PROCESS_STEP_UPDATE_SUCCESS = 'worfklow/AGREEMENT_PROCESS_STEP_UPDATE_SUCCESS';
export const WORKFLOW_AGREEMENT_PROCESS_STEP_UPDATE_FAILURE = 'worfklow/AGREEMENT_PROCESS_STEP_UPDATE_FAILURE';

export const WORKFLOW_AGREEMENT_PROCESS_STEPS_SORT = 'worfklow/AGREEMENT_PROCESS_STEPS_SORT';

export const WORKFLOW_AGREEMENT_PROCESS_SCENARIO_FETCH = 'worfklow/AGREEMENT_PROCESS_SCENARIO_FETCH_DATA';
export const WORKFLOW_AGREEMENT_PROCESS_SCENARIO_FETCH_REQUEST = 'worfklow/AGREEMENT_PROCESS_SCENARIO_FETCH_DATA_REQUEST';
export const WORKFLOW_AGREEMENT_PROCESS_SCENARIO_FETCH_SUCCESS = 'worfklow/AGREEMENT_PROCESS_SCENARIO_FETCH_DATA_SUCCESS';
export const WORKFLOW_AGREEMENT_PROCESS_SCENARIO_FETCH_FAILURE = 'worfklow/AGREEMENT_PROCESS_SCENARIO_FETCH_DATA_FAILURE';

export const WORKFLOW_AGREEMENT_PROCESS_SCENARIO_CREATE = 'worfklow/AGREEMENT_PROCESS_SCENARIO_CREATE_DATA';
export const WORKFLOW_AGREEMENT_PROCESS_SCENARIO_CREATE_REQUEST = 'worfklow/AGREEMENT_PROCESS_SCENARIO_CREATE_DATA_REQUEST';
export const WORKFLOW_AGREEMENT_PROCESS_SCENARIO_CREATE_SUCCESS = 'worfklow/AGREEMENT_PROCESS_SCENARIO_CREATE_DATA_SUCCESS';
export const WORKFLOW_AGREEMENT_PROCESS_SCENARIO_CREATE_FAILURE = 'worfklow/AGREEMENT_PROCESS_SCENARIO_CREATE_DATA_FAILURE';

export const WORKFLOW_AGREEMENT_PROCESS_SCENARIO_UPDATE = 'worfklow/AGREEMENT_PROCESS_SCENARIO_UPDATE';
export const WORKFLOW_AGREEMENT_PROCESS_SCENARIO_UPDATE_REQUEST = 'worfklow/AGREEMENT_PROCESS_SCENARIO_UPDATE_DATA_REQUEST';
export const WORKFLOW_AGREEMENT_PROCESS_SCENARIO_UPDATE_SUCCESS = 'worfklow/AGREEMENT_PROCESS_SCENARIO_UPDATE_DATA_SUCCESS';
export const WORKFLOW_AGREEMENT_PROCESS_SCENARIO_UPDATE_FAILURE = 'worfklow/AGREEMENT_PROCESS_SCENARIO_UPDATE_DATA_FAILURE';

export const WORKFLOW_AGREEMENT_PROCESS_SCENARIOS_FETCH_REQUEST = 'worfklow/AGREEMENT_PROCESS_SCENARIOS_FETCH_DATA_REQUEST';
export const WORKFLOW_AGREEMENT_PROCESS_SCENARIOS_FETCH_SUCCESS = 'worfklow/AGREEMENT_PROCESS_SCENARIOS_FETCH_DATA_SUCCESS';
export const WORKFLOW_AGREEMENT_PROCESS_SCENARIOS_FETCH_FAILURE = 'worfklow/AGREEMENT_PROCESS_SCENARIOS_FETCH_DATA_FAILURE';

export const WORKFLOW_AGREEMENT_PROCESS_USER_ACCESSIBLE_STEP_IDS_FETCH_REQUEST = 'worfklow/AGREEMENT_PROCESS_USER_ACCESSIBLE_STEP_IDS_FETCH_REQUEST';
export const WORKFLOW_AGREEMENT_PROCESS_USER_ACCESSIBLE_STEP_IDS_FETCH_SUCCESS = 'worfklow/AGREEMENT_PROCESS_USER_ACCESSIBLE_STEP_IDS_FETCH_SUCCESS';
export const WORKFLOW_AGREEMENT_PROCESS_USER_ACCESSIBLE_STEP_IDS_FETCH_FAILURE = 'worfklow/AGREEMENT_PROCESS_USER_ACCESSIBLE_STEP_IDS_FETCH_FAILURE';

export const WORKFLOW_QUEUE_RECORD_VARIABLE_UPDATE_REQUEST = 'worfklow/QUEUE_RECORD_VARIABLE_UPDATE_REQUEST';
export const WORKFLOW_QUEUE_RECORD_VARIABLE_UPDATE_SUCCESS = 'worfklow/QUEUE_RECORD_VARIABLE_UPDATE_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_VARIABLE_UPDATE_FAILURE = 'worfklow/QUEUE_RECORD_VARIABLE_UPDATE_FAILURE';

export const WORKFLOW_QUEUE_RECORD_UPDATE = 'worfklow/QUEUE_RECORD_UPDATE';
export const WORKFLOW_QUEUE_RECORD_UPDATE_REQUEST = 'worfklow/QUEUE_RECORD_UPDATE_REQUEST';
export const WORKFLOW_QUEUE_RECORD_UPDATE_SUCCESS = 'worfklow/QUEUE_RECORD_UPDATE_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_UPDATE_FAILURE = 'worfklow/QUEUE_RECORD_UPDATE_FAILURE';

export const WORKFLOW_QUEUE_STATES_FETCH_REQUEST = 'worfklow/QUEUE_STATES_FETCH_DATA_REQUEST';
export const WORKFLOW_QUEUE_STATES_FETCH_SUCCESS = 'worfklow/QUEUE_STATES_FETCH_DATA_SUCCESS';
export const WORKFLOW_QUEUE_STATES_FETCH_FAILURE = 'worfklow/QUEUE_STATES_FETCH_DATA_FAILURE';

export const WORKFLOW_QUEUE_RECORD_FETCH_EMAIL = 'worfklow/QUEUE_RECORD_FETCH_EMAIL';
export const WORKFLOW_QUEUE_RECORD_FETCH_EMAIL_REQUEST = 'worfklow/QUEUE_RECORD_FETCH_EMAIL_REQUEST';
export const WORKFLOW_QUEUE_RECORD_FETCH_EMAIL_SUCCESS = 'worfklow/QUEUE_RECORD_FETCH_EMAIL_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_FETCH_EMAIL_FAILURE = 'worfklow/QUEUE_RECORD_FETCH_EMAIL_FAILURE';

export const WORKFLOW_QUEUE_RECORD_FETCH_HISTORY = 'worfklow/QUEUE_RECORD_FETCH_HISTORY';
export const WORKFLOW_QUEUE_RECORD_FETCH_HISTORY_REQUEST = 'worfklow/QUEUE_RECORDD_FETCH_HISTORY_REQUEST';
export const WORKFLOW_QUEUE_RECORD_FETCH_HISTORY_SUCCESS = 'worfklow/QUEUE_RECORDD_FETCH_HISTORY_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_FETCH_HISTORY_FAILURE = 'worfklow/QUEUE_RECORDD_FETCH_HISTORY_FAILURE';

export const WORKFLOW_QUEUE_RECORD_FETCH_INFO = 'worfklow/QUEUE_RECORD_FETCH_INFO';
export const WORKFLOW_QUEUE_RECORD_FETCH_INFO_REQUEST = 'worfklow/QUEUE_RECORDD_FETCH_INFO_REQUEST';
export const WORKFLOW_QUEUE_RECORD_FETCH_INFO_SUCCESS = 'worfklow/QUEUE_RECORDD_FETCH_INFO_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_FETCH_INFO_FAILURE = 'worfklow/QUEUE_RECORDD_FETCH_INFO_FAILURE';

export const WORKFLOW_QUEUE_RECORD_FETCH_RELATED = 'worfklow/QUEUE_RECORD_FETCH_RELATED';
export const WORKFLOW_QUEUE_RECORD_FETCH_RELATED_REQUEST = 'worfklow/QUEUE_RECORDD_FETCH_RELATED_REQUEST';
export const WORKFLOW_QUEUE_RECORD_FETCH_RELATED_SUCCESS = 'worfklow/QUEUE_RECORDD_FETCH_RELATED_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_FETCH_RELATED_FAILURE = 'worfklow/QUEUE_RECORDD_FETCH_RELATED_FAILURE';

export const WORKFLOW_SCENARIO_FETCH_REQUEST = 'worfklow/SCENARIO_FETCH_DATA_REQUEST';
export const WORKFLOW_SCENARIO_FETCH_SUCCESS = 'worfklow/SCENARIO_FETCH_DATA_SUCCESS';
export const WORKFLOW_SCENARIO_FETCH_FAILURE = 'worfklow/SCENARIO_FETCH_DATA_FAILURE';

export const WORKFLOW_SCENARIOS_FETCH_REQUEST = 'worfklow/SCENARIOS_FETCH_DATA_REQUEST';
export const WORKFLOW_SCENARIOS_FETCH_SUCCESS = 'worfklow/SCENARIOS_FETCH_DATA_SUCCESS';
export const WORKFLOW_SCENARIOS_FETCH_FAILURE = 'worfklow/SCENARIOS_FETCH_DATA_FAILURE';

export const WORKFLOW_SCENARIOS_FETCH_WITHOUT_DELETED_REQUEST = 'worfklow/SCENARIOS_FETCH_DATA_WITHOUT_DELETED_REQUEST';
export const WORKFLOW_SCENARIOS_FETCH_WITHOUT_DELETED_SUCCESS = 'worfklow/SCENARIOS_FETCH_DATA_WITHOUT_DELETED_SUCCESS';
export const WORKFLOW_SCENARIOS_FETCH_WITHOUT_DELETED_FAILURE = 'worfklow/SCENARIOS_FETCH_DATA_WITHOUT_DELETED_FAILURE';

export const WORKFLOW_STEPS_FETCH_REQUEST = 'worfklow/STEPS_FETCH_DATA_REQUEST';
export const WORKFLOW_STEPS_FETCH_SUCCESS = 'worfklow/STEPS_FETCH_DATA_SUCCESS';
export const WORKFLOW_STEPS_FETCH_FAILURE = 'worfklow/STEPS_FETCH_DATA_FAILURE';

export const WORKFLOW_STEP_ADD_REQUEST = 'worfklow/STEP_ADD_REQUEST';
export const WORKFLOW_STEP_ADD_SUCCESS = 'worfklow/STEP_ADD_SUCCESS';
export const WORKFLOW_STEP_ADD_FAILURE = 'worfklow/STEP_ADD_FAILURE';

export const WORKFLOW_STEP_UPDATE_REQUEST = 'worfklow/STEP_UPDATE_REQUEST';
export const WORKFLOW_STEP_UPDATE_SUCCESS = 'worfklow/STEP_UPDATE_SUCCESS';
export const WORKFLOW_STEP_UPDATE_FAILURE = 'worfklow/STEP_UPDATE_FAILURE';

export const WORKFLOW_STEP_REMOVE_REQUEST = 'worfklow/STEP_REMOVE_REQUEST';
export const WORKFLOW_STEP_REMOVE_SUCCESS = 'worfklow/STEP_REMOVE_SUCCESS';
export const WORKFLOW_STEP_REMOVE_FAILURE = 'worfklow/STEP_REMOVE_FAILURE';

export const WORKFLOW_FETCH_SCENARIO_CONDITION_MEMBERS_REQUEST = 'worfklow/FETCH_SCENARIO_CONDITION_MEMBERS_REQUEST';
export const WORKFLOW_FETCH_SCENARIO_CONDITION_MEMBERS_SUCCESS = 'worfklow/FETCH_SCENARIO_CONDITION_MEMBERS_SUCCESS';
export const WORKFLOW_FETCH_SCENARIO_CONDITION_MEMBERS_FAILURE = 'worfklow/FETCH_SCENARIO_CONDITION_MEMBERS_FAILURE';

export const WORKFLOW_FETCH_CONDITION_MEMBERS_REQUEST = 'worfklow/FETCH_CONDITION_MEMBERS_REQUEST';
export const WORKFLOW_FETCH_CONDITION_MEMBERS_SUCCESS = 'worfklow/FETCH_CONDITION_MEMBERS_SUCCESS';
export const WORKFLOW_FETCH_CONDITION_MEMBERS_FAILURE = 'worfklow/FETCH_CONDITION_MEMBERS_FAILURE';

export const WORKFLOW_FETCH_STEP_ACTIONS_REQUEST = 'worfklow/FETCH_STEP_ACTIONS_REQUEST';
export const WORKFLOW_FETCH_STEP_ACTIONS_SUCCESS = 'worfklow/FETCH_STEP_ACTIONS_SUCCESS';
export const WORKFLOW_FETCH_STEP_ACTIONS_FAILURE = 'worfklow/FETCH_STEP_ACTIONS_FAILURE';

export const WORKFLOW_SCENARIO_CREATE = 'worfklow/SCENARIO_CREATE';
export const WORKFLOW_SCENARIO_CREATE_REQUEST = 'worfklow/SCENARIO_CREATE_REQUEST';
export const WORKFLOW_SCENARIO_CREATE_SUCCESS = 'worfklow/SCENARIO_CREATE_SUCCESS';
export const WORKFLOW_SCENARIO_CREATE_FAILURE = 'worfklow/SCENARIO_CREATE_FAILURE';

export const WORKFLOW_SCENARIO_UPDATE = 'worfklow/SCENARIO_UPDATE';
export const WORKFLOW_SCENARIO_UPDATE_REQUEST = 'worfklow/SCENARIO_UPDATE_REQUEST';
export const WORKFLOW_SCENARIO_UPDATE_SUCCESS = 'worfklow/SCENARIO_UPDATE_SUCCESS';
export const WORKFLOW_SCENARIO_UPDATE_FAILURE = 'worfklow/SCENARIO_UPDATE_FAILURE';

export const FTP_TEST_CONNECTION_REQUEST = 'ftp/TEST_CONNECTION_REQUEST_REQUEST';
export const FTP_TEST_CONNECTION_SUCCESS = 'ftp/TEST_CONNECTION_REQUEST_SUCCESS';
export const FTP_TEST_CONNECTION_FAILURE = 'ftp/TEST_CONNECTION_REQUEST_FAILURE';

export const WORKFLOW_QUEUE_RECORDS_MERGE_REQUEST = 'worfklow/QUEUE_RECORDS_MERGE_REQUEST';
export const WORKFLOW_QUEUE_RECORDS_MERGE_SUCCESS = 'worfklow/QUEUE_RECORDS_MERGE_SUCCESS';
export const WORKFLOW_QUEUE_RECORDS_MERGE_FAILURE = 'worfklow/QUEUE_RECORDS_MERGE_FAILURE';

export const WORKFLOW_QUEUE_RECORD_UNMERGE_REQUEST = 'worfklow/QUEUE_RECORD_UNMERGE_REQUEST';
export const WORKFLOW_QUEUE_RECORD_UNMERGE_SUCCESS = 'worfklow/QUEUE_RECORD_UNMERGE_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_UNMERGE_FAILURE = 'worfklow/QUEUE_RECORD_UNMERGE_FAILURE';

export const WORKFLOW_QUEUE_RECORDS_EMPTY_BIN_REQUEST = 'worfklow/QUEUE_RECORDS_EMPTY_BIN_REQUEST';
export const WORKFLOW_QUEUE_RECORDS_EMPTY_BIN_SUCCESS = 'worfklow/QUEUE_RECORDS_EMPTY_BIN_SUCCESS';
export const WORKFLOW_QUEUE_RECORDS_EMPTY_BIN_FAILURE = 'worfklow/QUEUE_RECORDS_EMPTY_BIN_FAILURE';

export const WORKFLOW_QUEUE_RECORD_REMOVE_REQUEST = 'worfklow/QUEUE_RECORD_REMOVE_REQUEST';
export const WORKFLOW_QUEUE_RECORD_REMOVE_SUCCESS = 'worfklow/QUEUE_RECORD_REMOVE_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_REMOVE_FAILURE = 'worfklow/QUEUE_RECORD_REMOVE_FAILURE';

export const WORKFLOW_TAB_TOGGLE_QUEUE_FILTER = 'workflow/TAB_TOGGLE_QUEUE_FILTER';

export const WORKFLOW_STATISTICS_CHART_TOTAL_DATA = 'worfklow/STATISTICS_CHART_TOTAL_DATA';
export const WORKFLOW_STATISTICS_CHART_TOTAL_DATA_REQUEST = 'worfklow/STATISTICS_CHART_TOTAL_DATA_REQUEST';
export const WORKFLOW_STATISTICS_CHART_TOTAL_DATA_SUCCESS = 'worfklow/STATISTICS_CHART_TOTAL_DATA_SUCCESS';
export const WORKFLOW_STATISTICS_CHART_TOTAL_DATA_FAILURE = 'worfklow/STATISTICS_CHART_TOTAL_DATA_FAILURE';

export const AGENDA_FETCH_DATA = 'agenda/FETCH_DATA';
export const AGENDA_FETCH_DATA_REQUEST = 'agenda/FETCH_DATA_REQUEST';
export const AGENDA_FETCH_DATA_SUCCESS = 'agenda/FETCH_DATA_SUCCESS';
export const AGENDA_FETCH_DATA_FAILURE = 'agenda/FETCH_DATA_FAILURE';

export const AGENDA_UPDATE_DATA = 'agenda/UPDATE_DATA';
export const AGENDA_UPDATE_DATA_REQUEST = 'agenda/UPDATE_DATA_REQUEST';
export const AGENDA_UPDATE_DATA_SUCCESS = 'agenda/UPDATE_DATA_SUCCESS';
export const AGENDA_UPDATE_DATA_FAILURE = 'agenda/UPDATE_DATA_FAILURE';

export const AGENDA_MAIL_FETCH_REQUEST = 'agendaMail/FETCH_REQUEST';
export const AGENDA_MAIL_FETCH_SUCCESS = 'agendaMail/FETCH_SUCCESS';
export const AGENDA_MAIL_FETCH_FAILURE = 'agendaMail/FETCH_FAILURE';

export const AGENDA_MAIL_UPDATE = 'agendaMail/UPDATE';
export const AGENDA_MAIL_UPDATE_REQUEST = 'agendaMail/UPDATE_REQUEST';
export const AGENDA_MAIL_UPDATE_SUCCESS = 'agendaMail/UPDATE_SUCCESS';
export const AGENDA_MAIL_UPDATE_FAILURE = 'agendaMail/UPDATE_FAILURE';

export const AGENDA_MAIL_CREATE = 'agendaMail/CREATE';
export const AGENDA_MAIL_CREATE_REQUEST = 'agendaMail/CREATE_REQUEST';
export const AGENDA_MAIL_CREATE_SUCCESS = 'agendaMail/CREATE_SUCCESS';
export const AGENDA_MAIL_CREATE_FAILURE = 'agendaMail/CREATE_FAILURE';

export const AGENDA_CLOUD_DRIVE_FETCH_REQUEST = 'agendaCloudDrive/FETCH_REQUEST';
export const AGENDA_CLOUD_DRIVE_FETCH_SUCCESS = 'agendaCloudDrive/FETCH_SUCCESS';
export const AGENDA_CLOUD_DRIVE_FETCH_FAILURE = 'agendaCloudDrive/FETCH_FAILURE';

export const AGENDA_CLOUD_DRIVE_CREATE = 'agendaCloudDrive/CREATE';
export const AGENDA_CLOUD_DRIVE_CREATE_REQUEST = 'agendaCloudDrive/CREATE_REQUEST';
export const AGENDA_CLOUD_DRIVE_CREATE_SUCCESS = 'agendaCloudDrive/CREATE_SUCCESS';
export const AGENDA_CLOUD_DRIVE_CREATE_FAILURE = 'agendaCloudDrive/CREATE_FAILURE';

export const AGENDA_CLOUD_DRIVE_UPDATE = 'agendaCloudDrive/UPDATE';
export const AGENDA_CLOUD_DRIVE_UPDATE_REQUEST = 'agendaCloudDrive/UPDATE_REQUEST';
export const AGENDA_CLOUD_DRIVE_UPDATE_SUCCESS = 'agendaCloudDrive/UPDATE_SUCCESS';
export const AGENDA_CLOUD_DRIVE_UPDATE_FAILURE = 'agendaCloudDrive/UPDATE_FAILURE';

export const AGENDA_CLOUD_DRIVES_FETCH_REQUEST = 'agendaCloudDrives/FETCH_DATA_REQUEST';
export const AGENDA_CLOUD_DRIVES_FETCH_SUCCESS = 'agendaCloudDrives/FETCH_DATA_SUCCESS';
export const AGENDA_CLOUD_DRIVES_FETCH_FAILURE = 'agendaCloudDrives/FETCH_DATA_FAILURE';

export const WORKFLOW_CREATE_VARIABLE_TYPE = 'worfklow/CREATE_VARIABLE_TYPE';
export const WORKFLOW_CREATE_VARIABLE_TYPE_REQUEST = 'worfklow/CREATE_VARIABLE_TYPE_REQUEST';
export const WORKFLOW_CREATE_VARIABLE_TYPE_SUCCESS = 'worfklow/CREATE_VARIABLE_TYPE_SUCCESS';
export const WORKFLOW_CREATE_VARIABLE_TYPE_FAILURE = 'worfklow/CREATE_VARIABLE_TYPE_FAILURE';

export const WORKFLOW_REMOVE_VARIABLE_TYPE_REQUEST = 'worfklow/REMOVE_VARIABLE_TYPE_REQUEST';
export const WORKFLOW_REMOVE_VARIABLE_TYPE_SUCCESS = 'worfklow/REMOVE_VARIABLE_TYPE_SUCCESS';
export const WORKFLOW_REMOVE_VARIABLE_TYPE_FAILURE = 'worfklow/REMOVE_VARIABLE_TYPE_FAILURE';

export const WORKFLOW_QUEUE_RECORD_RETURN_TO_SENDER_REQUEST = 'worfklow/QUEUE_RECORD_RETURN_TO_SENDER_REQUEST';
export const WORKFLOW_QUEUE_RECORD_RETURN_TO_SENDER_SUCCESS = 'worfklow/QUEUE_RECORD_RETURN_TO_SENDER_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_RETURN_TO_SENDER_FAILURE = 'worfklow/QUEUE_RECORD_RETURN_TO_SENDER_FAILURE';

export const WORKFLOW_QUEUE_RECORD_TERMINATE_PROCESS_REQUEST = 'worfklow/QUEUE_RECORD_TERMINATE_PROCESS_REQUEST';
export const WORKFLOW_QUEUE_RECORD_TERMINATE_PROCESS_SUCCESS = 'worfklow/QUEUE_RECORD_TERMINATE_PROCESS_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_TERMINATE_PROCESS_FAILURE = 'worfklow/QUEUE_RECORD_TERMINATE_PROCESS_FAILURE';

export const WORKFLOW_QUEUE_RECORD_GET_PARENT_GROUP_ID_REQUEST = 'worfklow/GET_PARENT_GROUP_ID_REQUEST';
export const WORKFLOW_QUEUE_RECORD_GET_PARENT_GROUP_ID_SUCCESS = 'worfklow/GET_PARENT_GROUP_ID_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_GET_PARENT_GROUP_ID_FAILURE = 'worfklow/GET_PARENT_GROUP_ID_FAILURE';

export const BILLING_BALANCE_FETCH_DATA_REQUEST = 'billing/BALANCE_FETCH_DATA_REQUEST';
export const BILLING_BALANCE_FETCH_DATA_SUCCESS = 'billing/BALANCE_FETCH_DATA_SUCCESS';
export const BILLING_BALANCE_FETCH_DATA_FAILURE = 'billing/BALANCE_FETCH_DATA_FAILURE';

export const BILLING_FETCH_DATA_REQUEST = 'billing/FETCH_DATA_REQUEST';
export const BILLING_FETCH_DATA_SUCCESS = 'billing/FETCH_DATA_SUCCESS';
export const BILLING_FETCH_DATA_FAILURE = 'billing/FETCH_DATA_FAILURE';

export const BILLING_UPDATE_DATA = 'billing/UPDATE_DATA';
export const BILLING_UPDATE_DATA_REQUEST = 'billing/UPDATE_DATA_REQUEST';
export const BILLING_UPDATE_DATA_SUCCESS = 'billing/UPDATE_DATA_SUCCESS';
export const BILLING_UPDATE_DATA_FAILURE = 'billing/UPDATE_DATA_FAILURE';

export const CREDIT_MAKE_PAYMENT = 'credit/MAKE_PAYMENT';
export const CREDIT_MAKE_PAYMENT_REQUEST = 'credit/MAKE_PAYMENT_REQUEST';
export const CREDIT_MAKE_PAYMENT_SUCCESS = 'credit/MAKE_PAYMENT_SUCCESS';
export const CREDIT_MAKE_PAYMENT_FAILURE = 'credit/MAKE_PAYMENT_FAILURE';

export const BILLING_TARIFFS_FETCH_REQUEST = 'billing/TARIFF_FETCH_REQUEST';
export const BILLING_TARIFFS_FETCH_SUCCESS = 'billing/TARIFF_FETCH_SUCCESS';
export const BILLING_TARIFFS_FETCH_FAILURE = 'billing/TARIFF_FETCH_FAILURE';

export const BILLING_GROUPED_TARIFF_ITEMS_FETCH_REQUEST = 'billing/GROUPED_TARIFF_ITEMS_FETCH_REQUEST';
export const BILLING_GROUPED_TARIFF_ITEMS_FETCH_SUCCESS = 'billing/GROUPED_TARIFF_ITEMS_FETCH_SUCCESS';
export const BILLING_GROUPED_TARIFF_ITEMS_FETCH_FAILURE = 'billing/GROUPED_TARIFF_ITEMS_FETCH_FAILURE';

export const BILLING_TARIFF_SET_NEXT = 'billing/TARIFF_SET_NEXT';
export const BILLING_TARIFF_SET_NEXT_REQUEST = 'billing/TARIFF_SET_NEXT_REQUEST';
export const BILLING_TARIFF_SET_NEXT_SUCCESS = 'billing/TARIFF_SET_NEXT_SUCCESS';
export const BILLING_TARIFF_SET_NEXT_FAILURE = 'billing/TARIFF_SET_NEXT_FAILURE';

export const CONTACT_US_SEND = 'contactUs/SEND';
export const CONTACT_US_SEND_REQUEST = 'contactUs/SEND_REQUEST';
export const CONTACT_US_SEND_SUCCESS = 'contactUs/SEND_SUCCESS';
export const CONTACT_US_SEND_FAILURE = 'contactUs/SEND_FAILURE';

export const ARCHIVE_DOCUMENT_FETCH_RELATED = 'archive/ARCHIVE_DOCUMENT_FETCH_RELATED';
export const ARCHIVE_DOCUMENT_FETCH_RELATED_REQUEST = 'archive/ARCHIVE_DOCUMENT_FETCH_RELATED_REQUEST';
export const ARCHIVE_DOCUMENT_FETCH_RELATED_SUCCESS = 'archive/ARCHIVE_DOCUMENT_FETCH_RELATED_SUCCESS';
export const ARCHIVE_DOCUMENT_FETCH_RELATED_FAILURE = 'archive/ARCHIVE_DOCUMENT_FETCH_RELATED_FAILURE';

export const ARCHIVE_DOCUMENT_SET_VALID_STATUS = 'archive/ARCHIVE_DOCUMENT_SET_VALID_STATUS';
export const ARCHIVE_DOCUMENT_SET_VALID_STATUS_REQUEST = 'archive/ARCHIVE_DOCUMENT_SET_VALID_STATUS_REQUEST';
export const ARCHIVE_DOCUMENT_SET_VALID_STATUS_SUCCESS = 'archive/ARCHIVE_DOCUMENT_SET_VALID_STATUS_SUCCESS';
export const ARCHIVE_DOCUMENT_SET_VALID_STATUS_FAILURE = 'archive/ARCHIVE_DOCUMENT_SET_VALID_STATUS_FAILURE';

export const ARCHIVE_FILE_FETCH = 'archive/ARCHIVE_FILE_FETCH';
export const ARCHIVE_FILE_FETCH_REQUEST = 'archive/ARCHIVE_FILE_FETCH_REQUEST';
export const ARCHIVE_FILE_FETCH_SUCCESS = 'archive/ARCHIVE_FILE_FETCH_SUCCESS';
export const ARCHIVE_FILE_FETCH_FAILURE = 'archive/ARCHIVE_FILE_FETCH_FAILURE';

export const ARCHIVE_CREATE_PACKAGE = 'archive/CREATE_PACKAGE';
export const ARCHIVE_CREATE_PACKAGE_REQUEST = 'archive/CREATE_PACKAGE_REQUEST';
export const ARCHIVE_CREATE_PACKAGE_SUCCESS = 'archive/CREATE_PACKAGE_SUCCESS';
export const ARCHIVE_CREATE_PACKAGE_FAILURE = 'archive/CREATE_PACKAGE_FAILURE';

export const ARCHIVE_FETCH_PACKAGE = 'archive/FETCH_PACKAGE';
export const ARCHIVE_FETCH_PACKAGE_REQUEST = 'archive/FETCH_PACKAGE_REQUEST';
export const ARCHIVE_FETCH_PACKAGE_SUCCESS = 'archive/FETCH_PACKAGE_SUCCESS';
export const ARCHIVE_FETCH_PACKAGE_FAILURE = 'archive/FETCH_PACKAGE_FAILURE';

export const ARCHIVE_PACKAGE_EXTEND = 'archive/PACKAGE_EXTEND';
export const ARCHIVE_PACKAGE_EXTEND_REQUEST = 'archive/PACKAGE_EXTEND_REQUEST';
export const ARCHIVE_PACKAGE_EXTEND_SUCCESS = 'archive/PACKAGE_EXTEND_SUCCESS';
export const ARCHIVE_PACKAGE_EXTEND_FAILURE = 'archive/PACKAGE_EXTEND_FAILURE';

export const ARCHIVE_PACKAGE_REACTIVATE = 'archive/PACKAGE_REACTIVATE';
export const ARCHIVE_PACKAGE_REACTIVATE_REQUEST = 'archive/PACKAGE_REACTIVATE_REQUEST';
export const ARCHIVE_PACKAGE_REACTIVATE_SUCCESS = 'archive/PACKAGE_REACTIVATE_SUCCESS';
export const ARCHIVE_PACKAGE_REACTIVATE_FAILURE = 'archive/PACKAGE_REACTIVATE_FAILURE';

export const ARCHIVE_PACKAGE_EXPIRE = 'archive/PACKAGE_EXPIRE';
export const ARCHIVE_PACKAGE_EXPIRE_REQUEST = 'archive/PACKAGE_EXPIRE_REQUEST';
export const ARCHIVE_PACKAGE_EXPIRE_SUCCESS = 'archive/PACKAGE_EXPIRE_SUCCESS';
export const ARCHIVE_PACKAGE_EXPIRE_FAILURE = 'archive/PACKAGE_EXPIRE_FAILURE';

export const ARCHIVE_PACKAGE_CANCEL = 'archive/PACKAGE_CANCEL';
export const ARCHIVE_PACKAGE_CANCEL_REQUEST = 'archive/PACKAGE_CANCEL_REQUEST';
export const ARCHIVE_PACKAGE_CANCEL_SUCCESS = 'archive/PACKAGE_CANCEL_SUCCESS';
export const ARCHIVE_PACKAGE_CANCEL_FAILURE = 'archive/PACKAGE_CANCEL_FAILURE';

export const ARCHIVE_RECIPIENT_SEND_MAIL = 'archive/RECIPIENT_SEND_MAIL';
export const ARCHIVE_RECIPIENT_SEND_MAIL_REQUEST = 'archive/RECIPIENT_SEND_MAIL_REQUEST';
export const ARCHIVE_RECIPIENT_SEND_MAIL_SUCCESS = 'archive/RECIPIENT_SEND_MAIL_SUCCESS';
export const ARCHIVE_RECIPIENT_SEND_MAIL_FAILURE = 'archive/RECIPIENT_SEND_MAIL_FAILURE';

export const ARCHIVE_RECIPIENT_SEND_PIN = 'archive/RECIPIENT_SEND_PIN';
export const ARCHIVE_RECIPIENT_SEND_PIN_REQUEST = 'archive/RECIPIENT_SEND_PIN_REQUEST';
export const ARCHIVE_RECIPIENT_SEND_PIN_SUCCESS = 'archive/RECIPIENT_SEND_PIN_SUCCESS';
export const ARCHIVE_RECIPIENT_SEND_PIN_FAILURE = 'archive/RECIPIENT_SEND_PIN_FAILURE';

export const ARCHIVE_RECIPIENT_GET_PACKAGE_LINK = 'archive/RECIPIENT_GET_PACKAGE_LINK';
export const ARCHIVE_RECIPIENT_GET_PACKAGE_LINK_REQUEST = 'archive/RECIPIENT_GET_PACKAGE_LINK_REQUEST';
export const ARCHIVE_RECIPIENT_GET_PACKAGE_LINK_SUCCESS = 'archive/RECIPIENT_GET_PACKAGE_LINK_SUCCESS';
export const ARCHIVE_RECIPIENT_GET_PACKAGE_LINK_FAILURE = 'archive/RECIPIENT_GET_PACKAGE_LINK_FAILURE';

export const ARCHIVE_RECIPIENT_ADD_FOR_PACKAGE = 'archive/RECIPIENT_ADD_FOR_PACKAGE';
export const ARCHIVE_RECIPIENT_ADD_FOR_PACKAGE_REQUEST = 'archive/RECIPIENT_ADD_FOR_PACKAGE_REQUEST';
export const ARCHIVE_RECIPIENT_ADD_FOR_PACKAGE_SUCCESS = 'archive/RECIPIENT_ADD_FOR_PACKAGE_SUCCESS';
export const ARCHIVE_RECIPIENT_ADD_FOR_PACKAGE_FAILURE = 'archive/RECIPIENT_ADD_FOR_PACKAGE_FAILURE';

// SignalR
export const SR_WORKFLOW_SELECTED_BY_USER = 'signalR/WORKFLOW_SELECTED_BY_USER';
export const SR_WORKFLOW_SELECT_BY_USER = 'signalR/WORKFLOW_SELECT_BY_USER';

export const VIRTUAL_DATA_SET = 'virtual/DATA_SET';

export const USER_AGENDA_SETTING_CREATE = 'userAgendaSetting/CREATE';
export const USER_AGENDA_SETTING_CREATE_REQUEST = 'userAgendaSetting/CREATE_REQUEST';
export const USER_AGENDA_SETTING_CREATE_SUCCESS = 'userAgendaSetting/CREATE_SUCCESS';
export const USER_AGENDA_SETTING_CREATE_FAILURE = 'userAgendaSetting/CREATE_FAILURE';

export const USER_AGENDA_SETTING_UPDATE = 'userAgendaSetting/UPDATE';
export const USER_AGENDA_SETTING_UPDATE_REQUEST = 'userAgendaSetting/UPDATE_REQUEST';
export const USER_AGENDA_SETTING_UPDATE_SUCCESS = 'userAgendaSetting/UPDATE_SUCCESS';
export const USER_AGENDA_SETTING_UPDATE_FAILURE = 'userAgendaSetting/UPDATE_FAILURE';

export const WEB_APP_SETTINGS_FETCH = ' webAppSettings/FETCH';
export const WEB_APP_SETTINGS_FETCH_REQUEST = ' webAppSettings/FETCH_REQUEST';
export const WEB_APP_SETTINGS_FETCH_SUCCESS = ' webAppSettings/FETCH_SUCCESS';
export const WEB_APP_SETTINGS_FETCH_FAILURE = ' webAppSettings/FETCH_FAILURE';

export const USER_TABLE_FILTERS_SETTINGS_FETCH = 'userTableFiltersSettings/FETCH';
export const USER_TABLE_FILTERS_SETTINGS_FETCH_REQUEST = 'userTableFiltersSettings/FETCH_REQUEST';
export const USER_TABLE_FILTERS_SETTINGS_FETCH_SUCCESS = 'userTableFiltersSettings/FETCH_SUCCESS';
export const USER_TABLE_FILTERS_SETTINGS_FETCH_FAILURE = 'userTableFiltersSettings/FETCH_FAILURE';

export const USER_WORKFLOW_FILTERS_SETTINGS_FETCH = 'userWorkflowFiltersSettings/FETCH';
export const USER_WORKFLOW_FILTERS_SETTINGS_FETCH_REQUEST = 'userWorkflowFiltersSettings/FETCH_REQUEST';
export const USER_WORKFLOW_FILTERS_SETTINGS_FETCH_SUCCESS = 'userWorkflowFiltersSettings/FETCH_SUCCESS';
export const USER_WORKFLOW_FILTERS_SETTINGS_FETCH_FAILURE = 'userWorkflowFiltersSettings/FETCH_FAILURE';

export const USER_WORKFLOW_FILTER_SETTINGS_REMOVE = 'userWorkflowFilterSettings/REMOVE';
export const USER_WORKFLOW_FILTER_SETTINGS_REMOVE_REQUEST = 'userWorkflowFilterSettings/REMOVE_REQUEST';
export const USER_WORKFLOW_FILTER_SETTINGS_REMOVE_SUCCESS = 'userWorkflowFilterSettings/REMOVE_SUCCESS';
export const USER_WORKFLOW_FILTER_SETTINGS_REMOVE_FAILURE = 'userWorkflowFilterSettings/REMOVE_FAILURE';

export const USER_DOCUMENTS_FOR_APPROVAL = 'userDocumentsForApproval/FETCH';
export const USER_DOCUMENTS_FOR_APPROVAL_REQUEST = 'userDocumentsForApproval/FETCH_REQUEST';
export const USER_DOCUMENTS_FOR_APPROVAL_SUCCESS = 'userDocumentsForApproval/FETCH_SUCCESS';
export const USER_DOCUMENTS_FOR_APPROVAL_FAILURE = 'userDocumentsForApproval/FETCH_FAILURE';

export const WORKFLOW_QUEUE_RECORD_MAIL_TO = 'worfklow/QUEUE_RECORD_MAIL_TO';
export const WORKFLOW_QUEUE_RECORD_MAIL_TO_REQUEST = 'worfklow/QUEUE_RECORD_MAIL_TO/FETCH_REQUEST';
export const WORKFLOW_QUEUE_RECORD_MAIL_TO_SUCCESS = 'worfklow/QUEUE_RECORD_MAIL_TO/FETCH_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_MAIL_TO_FAILURE = 'worfklow/QUEUE_RECORD_MAIL_TO/FETCH_FAILURE';

export const USER_WORKFLOW_FILTERS = "userFilters/WORKFLOW";
export const USER_TABLE_FILTERS_UPDATE = "userFilters/TABLES";
export const USER_APP_SETTINGS_UPDATE = "userSettings/APP_SETTINGS_UPDATE";

export const WORKFLOW_QUEUE_RECORD_EMAIL_HISTORY = 'worfklow/QUEUE_RECORD_EMAIL_HISTORY';
export const WORKFLOW_QUEUE_RECORD_EMAIL_HISTORY_REQUEST = 'worfklow/QUEUE_RECORD_EMAIL_HISTORY_REQUEST';
export const WORKFLOW_QUEUE_RECORD_EMAIL_HISTORY_SUCCESS = 'worfklow/QUEUE_RECORD_EMAIL_HISTORY_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_EMAIL_HISTORY_FAILURE = 'worfklow/QUEUE_RECORD_EMAIL_HISTORY_FAILURE';

export const NOTIFICATIONS_SETTING_FETCH_REQUEST = 'notifications/SETTING_FETCH_REQUEST';
export const NOTIFICATIONS_SETTING_FETCH_SUCCESS = 'notifications/SETTING_FETCH_SUCCESS';
export const NOTIFICATIONS_SETTING_FETCH_FAILURE = 'notifications/SETTING_FETCH_FAILURE';

export const AGENDA_SETTINGS_UPDATE = 'agendaSettings/UPDATE';
export const AGENDA_SETTINGS_UPDATE_REQUEST = 'agendaSettings/UPDATE_REQUEST';
export const AGENDA_SETTINGS_UPDATE_SUCCESS = 'agendaSettings/UPDATE_SUCCESS';
export const AGENDA_SETTINGS_UPDATE_FAILURE = 'agendaSettings/UPDATE_FAILURE';

export const WORKFLOW_QUEUE_RECORD_GRAPH = 'worfklow/QUEUE_RECORD_GRAPH';
export const WORKFLOW_QUEUE_RECORD_GRAPH_REQUEST = 'worfklow/QUEUE_RECORD_GRAPH_REQUEST';
export const WORKFLOW_QUEUE_RECORD_GRAPH_SUCCESS = 'worfklow/QUEUE_RECORD_GRAPH_SUCCESS';
export const WORKFLOW_QUEUE_RECORD_GRAPH_FAILURE = 'worfklow/QUEUE_RECORD_GRAPH_FAILURE';
export const WORKFLOW_QUEUE_RECORD_GRAPH_RESET = 'worfklow/QUEUE_RECORD_GRAPH_RESET';

export const WORKFLOW_INVOICE_FETCH = 'workflowInvoice/FETCH';
export const WORKFLOW_INVOICE_FETCH_REQUEST = 'workflowInvoice/FETCH_REQUEST';
export const WORKFLOW_INVOICE_FETCH_SUCCESS = 'workflowInvoice/FETCH_SUCCESS';
export const WORKFLOW_INVOICE_FETCH_FAILURE = 'workflowInvoice/FETCH_FAILURE';

export const WORKFLOW_INVOICE_UPDATE = 'workflowInvoice/UPDATE';
export const WORKFLOW_INVOICE_UPDATE_REQUEST = 'workflowInvoice/UPDATE_REQUEST';
export const WORKFLOW_INVOICE_UPDATE_SUCCESS = 'workflowInvoice/UPDATE_SUCCESS';
export const WORKFLOW_INVOICE_UPDATE_FAILURE = 'workflowInvoice/UPDATE_FAILURE';

export const ATTACHMENTS = 'attachments/ATTACHMENTS';
export const ATTACHMENTS_REQUEST = 'attachments/ATTACHMENTS_REQUEST';
export const ATTACHMENTS_SUCCESS = 'attachments/ATTACHMENTS_SUCCESS';
export const ATTACHMENTS_FAILURE = 'attachments/ATTACHMENTS_FAILURE';
export const ATTACHMENTS_RESET = 'attachments/ATTACHMENTS_RESET';

export const VAT_RATES_FETCH = 'vatRates/VAT_RATES_FETCH';
export const VAT_RATES_FETCH_REQUEST = 'vatRates/VAT_RATES_FETCH_REQUEST';
export const VAT_RATES_FETCH_SUCCESS = 'vatRates/VAT_RATES_FETCH_SUCCESS';
export const VAT_RATES_FETCH_FAILURE = 'vatRates/VAT_RATES_FETCH_FAILURE';

export const WORKFLOW_SCENARIO_MEMBERS_DATA = 'worfklow/SCENARIO_MEMBERS_DATA';
