import React, { useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';
import _ from 'lodash';
import { invokeAsync } from '../generalFunctions';
import useIsComponentMounted from 'hooks/useIsComponentMounted';

export interface FormChangeSpyProps {
  onFormChange: (dirty: boolean) => void,
  submittedValues?: any,
}

// it doesn't matter if field value null or empty string when detecting changes
const customizer = (objValue: any, othValue: any) => {
  if (objValue === othValue || (!objValue && !othValue)) {
    return true;
  }
};

const isEqual = (objValue: any, othValue: any) => _.isEqualWith(objValue, othValue, customizer);

const FormChangeSpy = ({ onFormChange, submittedValues }: FormChangeSpyProps) => {
  const [wasSubmitted, setSubmitted] = useState(false);
  const [cachedSubmittedValues, setCachedSubmittedValues] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const isMounted = useIsComponentMounted();

  useEffect(() => {
    if (submittedValues && !_.isEqual(submittedValues, cachedSubmittedValues)) {
      onFormChange(false);
      setCachedSubmittedValues(submittedValues);
    }
  }, [submittedValues, onFormChange]);

  return (
    <>
      <FormSpy
        subscription={{ submitSucceeded: true }}
        onChange={({ submitSucceeded }) => {
          if (!isMounted.current) {
            return;
          }
          if (submitSucceeded && !wasSubmitted) {
            invokeAsync(() => setSubmitted(true));
            onFormChange(false);
          }
        }}
      />
      <FormSpy
        subscription={{ values: true, initialValues: true }}
        onChange={(props) => {
          if (!isMounted.current) {
            return;
          }
          const { values, initialValues } = props;
          const equal = submittedValues ? isEqual(values, submittedValues) : isEqual(values, initialValues);
          const hasNewChanges = !equal;
          if (hasNewChanges !== hasChanges) {
            setHasChanges(hasNewChanges);
            invokeAsync(() => onFormChange(hasNewChanges));
          }
        }}
      />
    </>
  );
};

export default FormChangeSpy;
