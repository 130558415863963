import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { AppDispatch } from "../AppRoot";
import { API_PATHS } from "../common/data/routes";
import { AppViewModeType } from "../common/types/appTypes";
import {
  LastSeenNotifyDates,
  SETTINGS_RELATION_TYPE,
  SETTINGS_TYPE,
  SETTING_CATEGORY,
  SettingCategory,
  UserAgendaSettings,
  UserAgendaSettingsModel,
  UserAgendaSettingsModelBase,
  WebAppSettings,
} from "../common/types/userAgendaSettingsTypes";

import * as types from './actionTypes';
import { alertAdd } from "./alertActions";
import * as alertTypes from './alertTypes';
import { fetchNotifications } from "./notificationActions";
import { RootState } from "../common/types/reduxStateTypes";
import { NOTIFICATION_TYPES } from "../common/types/notificationTypes";
import { userAppSettingsInitialState } from "../reducers/initialStates";
import { Filter } from "../common/types/gridTypes";
import { WorkflowVariablesPredictionSettings } from "common/types/predictionAndRulesTypes";

export const fetchWebAppSettings = () => (dispatch: AppDispatch) => {
  dispatch({
    types: [
      types.WEB_APP_SETTINGS_FETCH_REQUEST,
      types.WEB_APP_SETTINGS_FETCH_SUCCESS,
      types.WEB_APP_SETTINGS_FETCH_FAILURE
    ],
    callAPI: () => {
      const category = `${SETTING_CATEGORY.WebApplicationSettings}`;
      const apiPath = API_PATHS.USER_AGENDA_SETTING_BY_CATEGORY.replace('[:category]', category);
      return axios.get(apiPath);
    },
    callSuccessAction: (response: { data: UserAgendaSettingsModel }) => {
      const settings = mapSetting(response.data);
      if (!settings.data) {
        settings.data = getInitialWebAppSettings();
      }
      dispatch({
        type: types.USER_APP_SETTINGS_UPDATE,
        data: settings,
      });
      dispatch(fetchNotifications());
    },
  });
};

const getInitialWebAppSettings = () => {
  const lastSeenNotifyDates: LastSeenNotifyDates = {};
  Object.values(NOTIFICATION_TYPES).forEach((path) => {
    lastSeenNotifyDates[path] = new Date();
  });
  const { data } = userAppSettingsInitialState;

  const webAppSettings: WebAppSettings = {
    ...data,
    lastSeenNotifyDates,
  };

  return webAppSettings;
};

export const fetchTableFilterSettings = () => (dispatch: AppDispatch) => {
  dispatch({
    types: [
      types.USER_TABLE_FILTERS_SETTINGS_FETCH_REQUEST,
      types.USER_TABLE_FILTERS_SETTINGS_FETCH_SUCCESS,
      types.USER_TABLE_FILTERS_SETTINGS_FETCH_FAILURE
    ],
    callAPI: () => {
      const category = `${SETTING_CATEGORY.TableFiltersSettings}`;
      const apiPath = API_PATHS.USER_AGENDA_SETTING_BY_CATEGORY.replace('[:category]', category);
      return axios.get(apiPath);
    },
    callSuccessAction: (response: { data: UserAgendaSettingsModel }) => {
      const settings = mapSetting(response.data);
      if (!settings.data) {
        settings.data = {};
      }
      dispatch({
        type: types.USER_TABLE_FILTERS_UPDATE,
        tableFilters: settings,
      });
    },
  });
};

export const fetchPredictionSettings = async () => {
  const category = `${SETTING_CATEGORY.WorkflowVariablesPredictionSettings}`;
  const apiPath = API_PATHS.AGENDA_SETTINGS_BY_CATEGORY.replace('[:category]', category);
  try {
    const response = await axios.get(apiPath);
    const settings = response.data;
    const predictionSettings: WorkflowVariablesPredictionSettings = settings.data ?
      JSON.parse(settings.data) :
      { header: {}, items: {} };
    return { id: settings.id, data: predictionSettings };
  } catch {
    return null;
  }
};

export const fetchWorkflowFilters = () => (dispatch: AppDispatch) => {
  dispatch({
    types: [
      types.USER_WORKFLOW_FILTERS_SETTINGS_FETCH_REQUEST,
      types.USER_WORKFLOW_FILTERS_SETTINGS_FETCH_SUCCESS,
      types.USER_WORKFLOW_FILTERS_SETTINGS_FETCH_FAILURE
    ],
    callAPI: () => {
      const category = `${SETTING_CATEGORY.WorkflowInputQueueFilterSettings}`;
      const apiPath = API_PATHS.USER_AGENDA_SETTINGS_BY_CATEGORY.replace('[:category]', category);
      return axios.get(apiPath);
    },
    callSuccessAction: (response: { data: UserAgendaSettingsModel[] }) => {
      dispatch({
        type: types.USER_WORKFLOW_FILTERS,
        workflowFilters: response.data.map(s => mapSetting(s)),
      });
    },
  });
};

export const createUserAgendaSettings = (category: SettingCategory) =>
  (settings: object, callback?: (settings: UserAgendaSettings) => void) => (dispatch: AppDispatch) => {
    dispatch({
      types: [
        types.USER_AGENDA_SETTING_CREATE_REQUEST,
        types.USER_AGENDA_SETTING_CREATE_SUCCESS,
        types.USER_AGENDA_SETTING_CREATE_FAILURE
      ],
      callAPI: () => createSetting(settings, category),
      callSuccessAction: (response: { data: UserAgendaSettingsModel }) => {
        const { id, data } = response.data;
        const settings: UserAgendaSettings = { id, data: JSON.parse(data) };
        callback?.(settings);
      },
    });
  };

export const updateUserAgendaSettings = (settings: UserAgendaSettings, callback?: (settings: UserAgendaSettingsModel) => void) => (dispatch: AppDispatch) => {
  dispatch({
    types: [
      types.USER_AGENDA_SETTING_UPDATE_REQUEST,
      types.USER_AGENDA_SETTING_UPDATE_SUCCESS,
      types.USER_AGENDA_SETTING_UPDATE_FAILURE
    ],
    callAPI: () => updateSetting(settings),
    callSuccessAction: (response: { data: UserAgendaSettingsModel }) => {
      callback?.(response.data);
    },
  });
};

const mapSetting = (settings: UserAgendaSettingsModel): UserAgendaSettings => ({ id: settings.id, data: settings.data ? JSON.parse(settings.data) : null });

export const setUserViewMode = (userViewMode: AppViewModeType, pageName: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const { userAppSettings } = getState();
  const { id, data } = userAppSettings;
  const newData = { ...data, userViewModes: { ...data.userViewModes, [pageName]: userViewMode } };
  const newUserAppSettings = { id, data: newData };

  updateSetting(newUserAppSettings);
  return dispatch({
    type: types.USER_APP_SETTINGS_UPDATE,
    data: newUserAppSettings
  });
};

export const setColumnNames = (tableColumnNames: string[], tableName: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const { userAppSettings } = getState();
  const { id, data } = userAppSettings;
  const newData = { ...data, columnNames: { ...data.columnNames, [tableName]: tableColumnNames } };
  const newUserAppSettings = { id, data: newData };

  updateSetting(newUserAppSettings);
  return dispatch({
    type: types.USER_APP_SETTINGS_UPDATE,
    data: newUserAppSettings
  });
};

export const setUserWorkflowFilterSettings = (filterId: number, isActive: boolean, callback?: () => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { userAppSettings } = getState();
  const { id, data } = userAppSettings;
  const newData = { ...data, workflowFilter: { filterId, isActive } };
  const newUserAppSettings = { id, data: newData };

  await updateSetting(newUserAppSettings);
  callback?.();
  return dispatch({
    type: types.USER_APP_SETTINGS_UPDATE,
    data: newUserAppSettings
  });
};

export const refreshLastSeenNotifyDate = (path: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const { userAppSettings } = getState();
  const { id, data } = userAppSettings;
  const newData = { ...data, lastSeenNotifyDates: { ...data.lastSeenNotifyDates, [path]: new Date() } };
  const newUserAppSettings = { id, data: newData };

  updateSetting(newUserAppSettings);
  return dispatch({
    type: types.USER_APP_SETTINGS_UPDATE,
    data: newUserAppSettings
  });
};

export const updateUserTableFilters = (tableName: string, filters: Filter[]) => (dispatch: AppDispatch, getState: () => RootState) => {
  const { userTableFilters } = getState();
  const { id, data } = userTableFilters;
  const newData = { ...data, [tableName]: filters };
  const newUserTableFilters = { id, data: newData };

  if (id !== 0) {
    updateSetting(newUserTableFilters);
    return dispatch({
      type: types.USER_TABLE_FILTERS_UPDATE,
      tableFilters: newUserTableFilters
    });
  }
};

export const deleteUserWorkflowFilterSettings = (id: number, callback?: () => void) => (dispatch: AppDispatch) => {
  dispatch({
    types: [
      types.USER_WORKFLOW_FILTER_SETTINGS_REMOVE_REQUEST,
      types.USER_WORKFLOW_FILTER_SETTINGS_REMOVE_SUCCESS,
      types.USER_WORKFLOW_FILTER_SETTINGS_REMOVE_FAILURE
    ],
    callAPI: () => axios.delete(API_PATHS.SETTINGS, { data: [id] }),
    callSuccessAction: () => {
      dispatch(alertAdd("RemoveSuccess", alertTypes.SUCCESS));
      callback?.();
    },
    callFailureAction: () => dispatch(alertAdd("RemoveFailure", alertTypes.ERROR)),
    payload: { id }
  });
};

export const createSetting = async (settingsData: object, category: SettingCategory) => {
  const relationTypeValue = SETTINGS_RELATION_TYPE.userAgenda;
  const apiPath = API_PATHS.USER_AGENDA_SETTING_CREATE.replace('[:type]', String(relationTypeValue));

  const data: UserAgendaSettingsModelBase = {
    category: category,
    guidOfModified: uuidv4(),
    nameOfModified: 'WEBCLIENT',
    data: JSON.stringify(settingsData),
    type: SETTINGS_TYPE.web,
    dataStructureVersion: 1,
  };
  return axios.post(apiPath, data);
};

export const updateSetting = async (settings: UserAgendaSettings) => {
  const { id, data } = settings;
  const settingsModel = {
    id,
    data: JSON.stringify(data),
  };

  return axios.put(API_PATHS.USER_AGENDA_SETTING_UPDATE, settingsModel);
};
